import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, onUnmounted, provide, ref } from "vue";
import { fetchUserLocation } from "./services/api/appApi";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const screenWidth = ref(window.innerWidth);

provide("screenWidth", screenWidth);

const updateWindowsSize = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(async () => {
  // fetchUserCountry();
  updateWindowsSize();
  window.addEventListener("resize", updateWindowsSize);
});



onUnmounted(() => {
  window.removeEventListener("resize", updateWindowsSize);
});
const userCountry = ref("");

// Fetch the user's country
const fetchUserCountry = async () => {
  try {
    userCountry.value = (await fetchUserLocation()).country_name;
  } catch (error) {
    console.error("Error fetching user country:", error);
  }
};

// Provide the user's country
provide("userCountry", userCountry);
// provide("userCountry", "United State");
// Fetch the user's country when the app starts
fetchUserCountry();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})