<template>
  <router-view />
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, provide, ref } from "vue";
import { fetchUserLocation } from "./services/api/appApi";

const screenWidth = ref(window.innerWidth);

provide("screenWidth", screenWidth);

const updateWindowsSize = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(async () => {
  // fetchUserCountry();
  updateWindowsSize();
  window.addEventListener("resize", updateWindowsSize);
});



onUnmounted(() => {
  window.removeEventListener("resize", updateWindowsSize);
});
const userCountry = ref("");

// Fetch the user's country
const fetchUserCountry = async () => {
  try {
    userCountry.value = (await fetchUserLocation()).country_name;
  } catch (error) {
    console.error("Error fetching user country:", error);
  }
};

// Provide the user's country
provide("userCountry", userCountry);
// provide("userCountry", "United State");
// Fetch the user's country when the app starts
fetchUserCountry();
</script>
<style></style>
