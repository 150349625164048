import axios from "axios";

export async function fetchUserLocation() {
  try {
    // Fetch user's IP address
    const ipResponse = await axios.get("https://api.ipify.org?format=json");
    const ipData = ipResponse.data;

    // Fetch user's location based on IP address
    const locationResponse = await axios.get(`https://ipapi.co/${ipData.ip}/json/`);
    const locationData = locationResponse.data;

    return locationData; // Return locationData
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw error for handling outside this function if necessary
  }
}
