import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import "./registerServiceWorker";
import router from "./router";
import "animate.css";
import "@/assets/styles/shared/main.css";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/aura-light-noir/theme.css";


const pinia = createPinia();
const app = createApp(App);
app.use(pinia);

app.config.errorHandler = (err, vm, info) => {
  console.error("Error:", err);
  console.error("Vue component:", vm);
  console.error("Additional info:", info);
};

app.use(router);
app.use(PrimeVue);

app.mount("#app");
