import { useAuthStore } from "@/stores/";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeView",
    component: () => import("@/views/HomeView"), // Redirect '/' to '/login'
  },
  {
    path: "/authorize",
    name: "Authorize",
    component: () => import("@/views/AuthView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/views/SignUpView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/verify-otp",
    name: "Verify_otp",
    component: () => import("@/views/CodeView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: () => import("@/views/DashboardView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/folders",
    name: "Folders",
    component: () => import("@/views/FoldersView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/recovery",
    name: "RecoverPassword",
    component: () => import("@/views/RecoverPasswordView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/reset-password/:MJM/:token",
    name: "ResetPassword",
    component: () => import("@/views/ResetPasswordView"),
    meta: { requiresAuth: false },
  },
  {
    path: "/upload-template/:folder_id",
    name: "UploadTemplate",
    component: () => import("@/views/UploadTemplateView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/recent-uploads",
    name: "RecentUpload",
    component: () => import("@/views/RecentUploadView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/overdue",
    name: "OverDue",
    component: () => import("@/views/OverDueView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/shared",
    name: "SharedWithMe",
    component: () => import("@/views/SharedView/SharedWithMeView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/folder/:folder_id/access/",
    name: "SubmitFile",
    component: () => import("@/views/SubmissionView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/folders/:folder_id/",
    name: "ViewFolder",
    component: () => import("@/views/FolderView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/analyze-history/:file_id/",
    name: "AnalyzeHistory",
    component: () => import("@/views/AnalyzedFileView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "ProfileView",
    component: () => import("@/views/ProfileView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profileMenu",
    name: "ProfileMenu",
    component: () => import("@/views/ProfileMenu"),
    meta: { requiresAuth: true },
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: () => import("@/views/SubscriptionView/SubscriptionView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/manageSubscription",
    name: "manageSubscription",
    component: () => import("@/views/SubscriptionView/ManageSubscription.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("@/views/ChangePasswordView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/change-details",
    name: "ChangeDetails",
    component: () => import("@/views/ChangeDetailsView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/change-picture",
    name: "ChangeProfilePicture",
    component: () => import("@/views/ChangeProfilePictureView"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("@/views/PricingView"),
  },
  {
    path: "/use-cases",
    name: "useCases",
    component: () => import("@/views/UseCasesAndCaseStudiesView/UseCaseView"),
  },

  {
    path: "/privacy",
    name: "Privacy Policy",
    component: () => import("@/views/PrivacyPolicyView"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/TermsOfService"),
  },
  {
    path: "/organisation",
    name: "Organisation",
    component: () => import("@/views/SharedView/OrganisationView"),
  },
  {
    path: "/blog",
    name: "BlogView",
    component: () => import("@/views/BlogView"),
  },
  {
    path: "/blog/post/:post_id/",
    name: "SingleBlogPost",
    component: () => import("@/views/BlogView/SingleBlogPostView"),
  },
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: () => import("@/views/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = localStorage.getItem("currentUser") && JSON.parse(localStorage.getItem("currentUser") || "");
  if (currentUser && to.meta.requiresAuth === false) {
    // Redirect authenticated users away from auth pages
    next({ path: "/dashboard" });
  } else if (!currentUser && to.meta.requiresAuth === true) {
    // Redirect unauthenticated users to the login page
    next({ path: "/login", query: { redirect: to.fullPath.slice(1) } });
  } else {
    // Otherwise, allow the navigation
    next();
  }
});

export default router;
